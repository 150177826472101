<template>
  <div>
    <v-card>
      <v-snackbar
        v-model="sbAsignar"
        :timeout="timeout"
        :top="true"
        :absolute="true"
        color="#333333"
      >
        {{ mensajeSbAsignar }}
        <v-btn color="green" text small @click="sbAsignar = false">
          Cerrar
        </v-btn>
      </v-snackbar>

      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-format-list-checks</v-icon>
          GUIAS EN RESERVA WEB
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="busqueda"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-5"
        >
        </v-text-field>
        <v-spacer></v-spacer>

        <v-select
          v-model="filtroEtiquetas"
          :items="etiquetas"
          label="Etiquetas"
          item-value="id"
          item-text="desc"
          multiple
          chips
          persistent-hint
          class="mt-2"
          single-line
          @change="cargarLista()"
        ></v-select>
        <v-spacer></v-spacer>
        <v-select
          v-model="filtroEstado"
          :items="estados"
          label="Estado"
          item-value="id"
          item-text="desc"
          persistent-hint
          class="mt-2"
          single-line
          clearable
          @change="cargarLista()"
        ></v-select>
      </v-toolbar>

      <!-- <v-toolbar>
        <v-toolbar-title>Etiquetas</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
      </v-toolbar> -->

      <v-toolbar color="white">
        <v-btn small class="primary mx-1" @click="formAsignarVariedad()"
          >Asignar Variedad</v-btn
        >
        <v-btn small class="primary mx-2" @click="asignarCoordWeb()"
          >Coordinación web</v-btn
        >
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :search="busqueda"
        :loading="loadingTable"
        class="elevation-1"
        show-select
        v-model="seleccion"
        item-key="CABECERA_ID"
      >
        <template v-slot:item.opcion="{ item }">
          <!-- <v-btn text title="editar tipo de guia" @click="abrirGuia(item)">
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-btn> -->
        </template>

        <template v-slot:item.RESERVA_AUTO="{ item }">
          <v-chip
            title="reserva automática"
            v-if="item.RESERVA_AUTO == 'A'"
            small
            class="ma-1"
            color="yellow"
            @click="abrirGuia(item)"
          >
            {{ item.RESERVA_AUTO }}
          </v-chip>
          <v-btn
            v-if="item.RESERVA_AUTO == null"
            text
            title="editar tipo de guia"
            @click="abrirGuia(item)"
          >
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.IDENT_BIENES="{ item }">
          <v-chip
            title="consolidado"
            v-if="item.IDENT_BIENES == 'CONS'"
            small
            class="ma-1"
            color="red"
            text-color="white"
            @click="abrirGuia(item)"
          >
            C
          </v-chip>
          <v-chip
            title="simple"
            v-if="item.IDENT_BIENES == 'FF'"
            small
            class="ma-1"
            color="purple"
            text-color="white"
            @click="abrirGuia(item)"
          >
            S
          </v-chip>

          <v-btn
            v-if="item.IDENT_BIENES == null"
            text
            title="editar tipo de guia"
            @click="abrirGuia(item)"
          >
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.params_coord="{ item }">
          <span v-for="i in item.params_coord">
            <v-chip
              v-if="i.TIPO_PARAM == 'COORD_WEB'"
              small
              class="ma-1"
              close
              color="green"
              @click:close="eliminarOpcion(i.PARAM_COORD_ID)"
            >
              {{ i.VALOR_PARAM }}
            </v-chip>

            <v-chip
              v-if="i.TIPO_PARAM == 'VARIEDAD'"
              small
              class="ma-1"
              close
              :color="colorVariedad(i.VALOR_PARAM)"
              @click:close="eliminarOpcion(i.PARAM_COORD_ID)"
            >
              {{ i.VALOR_PARAM }}
            </v-chip>
          </span>
        </template>
        <template v-slot:item.CLIENTE_NOMBRE="{ item }">
          {{ item.CLIENTE_NOMBRE }} {{ item.MARCACION }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="formAsignar" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formAsignarTitulo }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formAsignar">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-autocomplete
                    v-model="variedadSel"
                    small
                    item-text="VARIEDAD"
                    item-value="VARIEDAD"
                    :items="variedades"
                    label="Variedades"
                    cache-items
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn color="primary" @click="asignarVariedad()">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            <v-btn @click="formAsignar = !formAsignar">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGuia" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogGuiaTitulo }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formGuia">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-checkbox
                    label="Reserva automática"
                    small
                    v-model="resAuto"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-select
                    v-model="tipoGuiaSel"
                    small
                    item-text="tipoGuiaDesc"
                    item-value="tipoGuiaId"
                    :items="tiposGuia"
                    label="Tipo de guia"
                    cache-items
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small class="mx-1" color="primary" @click="actualizarGuia()">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            <v-btn small class="mx-1" @click="dialogGuia = !dialogGuia">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";
import { timeout } from "q";

export default {
  name: "ListaMawbCoordComponent",
  data: () => ({
    headers: [
      { text: "Opcion", value: "opcion" },
      { text: "Confirma. Auto.", value: "RESERVA_AUTO" },
      { text: "Tipo", value: "IDENT_BIENES" },
      { text: "AWB", value: "AWB" },
      { text: "Opciones para coordinar", value: "params_coord" },
      { text: "Fecha", value: "FECHA" },
      { text: "Cliente", value: "CLIENTE_NOMBRE" },
      { text: "Aerolinea", value: "AEROLINEA_NOMBRE" },
      { text: "Estado", value: "ESTADO_GUIA" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    lista: [],
    seleccion: [],
    options: {},
    estados: [
      { id: "ABIERTA", desc: "ABIERTA" },
      { id: "RESERVADA", desc: "RESERVADA" },
      { id: "CERRADA", desc: "CERRADA" },
    ],
    notifications: false,
    //sound: true,
    //widgets: true,
    formAsignar: false,
    busqueda: null,
    formAsignarTitulo: "",
    variedades: ["ROSAS", "FLOR DE VERANO"],
    datosDae: [],
    itemsPerPage: 20,
    hcoordsAbiertas: [],
    variedadSel: "",
    filtroEstado: "",
    varcol: [
      { var: "ROSAS", color: "#ff3471" },
      { var: "FLOR DE VERANO", color: "#2267FF" },
    ],

    etiquetas: [
      { id: "ROSAS", desc: "ROSAS" },
      { id: "FLOR DE VERANO", desc: "FLOR DE VERANO" },
      { id: "COORDINACION WEB", desc: "COORDINACION WEB" },
    ],
    filtroEtiquetas: [],
    dialogGuia: false,
    dialogGuiaTitulo: "Guia aerea",
    tiposGuia: [
      { tipoGuiaId: "CONS", tipoGuiaDesc: "CONSOLIDADO" },
      { tipoGuiaId: "FF", tipoGuiaDesc: "SIMPLE" },
    ],
    tipoGuiaSel: "",
    resAuto: "",
    datosGuia: { awb: "", ident_bienes: "", res_auto: "" },
    sbAsignar: false,
    mensajeSbAsignar: "",
    timeout: 3000,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user"]),

    ...mapGetters("access", []),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    colorVariedad(x) {
      for (let i = 0; i <= this.varcol.length; i++) {
        if (this.varcol[i].var == x) {
          return this.varcol[i].color;
        }
      }
    },
    asignarCoordWeb() {
      console.log(this.seleccion);
      let cont = 0;
      for (let i = 0; i < this.seleccion.length; i++) {
        if (this.seleccion[i].ABIERTA == 0) {
          cont++;
        }
      }

      let cont2 = 0;
      for (let i = 0; i < this.seleccion.length; i++) {
        if (
          this.seleccion[i].IDENT_BIENES == "" ||
          this.seleccion[i].IDENT_BIENES == null
        ) {
          cont2++;
        }
      }

      if (cont > 0) {
        alert("Solo se pueden asignar guias ABIERTAS ");
        return false;
      }

      if (cont2 > 0) {
        alert("Ingrese el tipo de guía para poder asignar ");
        return false;
      }

      this.setUrl("api/asignar-coord-web");
      this.requestApi({
        method: "POST",
        data: {
          guias: this.seleccion,
        },
      })
        .then((res) => {
          //console.log(res);
          this.cargarLista();
          this.seleccion = [];
          this.mensajeSbAsignar = "Guia asignada a coordinación web";
          this.sbAsignar = true;
        })
        .catch(() => {})
        .then(() => {
          this.formDae = false;
        });
    },

    asignarVariedad() {
      console.log(this.seleccion);

      this.setUrl("api/asignar-param-variedad");
      this.requestApi({
        method: "POST",
        data: {
          guias: this.seleccion,
          variedad: this.variedadSel,
        },
      })
        .then((res) => {
          console.log(res);
          this.cargarLista();
          this.seleccion = [];
        })
        .catch(() => {})
        .then(() => {
          this.formAsignar = false;
        });
    },

    formAsignarVariedad() {
      this.formAsignar = true;
    },

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-mawb-coord");
      this.requestApi({
        method: "GET",
        data: { etiquetas: this.filtroEtiquetas, estados: this.filtroEstado },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data.lista;

          this.pageCount = 10;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    eliminarOpcion(ID) {
      Vue.swal({
        html: "Está seguro de eliminar la opción ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-param-coord-guia");
          this.requestApi({
            method: "DELETE",
            data: {
              PARAM_COORD_ID: ID,
            },
          }).then((res) => {
            this.alertNotification({ param: { html: res.data.msg } });

            this.cargarLista();
            //let obj = this.permisos.find(e => e.permission_id == permissionId)
            //let index = this.permisos.indexOf(obj)
            //this.permisos.splice(index,1)
          });
        }
      });
    },
    abrirGuia(guia) {
      this.dialogGuia = true;
      this.setUrl("api/cargar-guia");
      this.requestApi({
        method: "POST",
        data: {
          awb: guia.AWB,
        },
      })
        .then((res) => {
          //console.log(res.data.datos_guia[0]);
          this.datosGuia.awb = res.data.datos_guia[0].AWB;
          this.datosGuia.ident_bienes = res.data.datos_guia[0].IDENT_BIENES;
          this.tipoGuiaSel = res.data.datos_guia[0].IDENT_BIENES;
          this.resAuto =
            res.data.datos_guia[0].RESERVA_AUTO != null ? "A" : null;
        })
        .catch(() => {})
        .then(() => {
          //this.formDae = false;
        });
    },

    actualizarGuia() {
      this.setUrl("api/actualizar-guia");
      this.requestApi({
        method: "POST",
        data: {
          awb: this.datosGuia.awb,
          ident_bienes: this.tipoGuiaSel,
          res_auto: this.resAuto == true ? "A" : null,
        },
      })
        .then((res) => {
          //console.log(res);
          this.dialogGuia = false;
          this.datosGuia = { awb: "", ident_bienes: "", res_auto: "" };
          this.tipoGuiaSel = "";
          this.resAuto = "";
          this.mensajeSbAsignar = "Guia actualizada con éxito";
          this.sbAsignar = true;
          this.cargarLista();
        })
        .catch(() => {})
        .then(() => {});
    },
  },
  mounted() {
    this.cargarLista();
  },
};
</script>
